@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.container {
  display: flex;
  align-items: center;
  color: #fff;

  position: relative;
  background-blend-mode: overlay;
  /* Overlay the background color */
  backdrop-filter: blur(8px);
  /* Optional: Blur effect for background */
  min-height: 640px;
  max-width: 100vw;


  & .overlay {
    position: absolute;
    top: 0;
    left: 36%;
    right: 0;
    bottom: 0;
    object-fit: cover;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 767px) {
      background-size: contain !important;
      background-position: top !important;
      left: 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(238deg, rgba(0, 0, 0, 0) 29.41%, #000 57.86%);
    /* background: linear-gradient(231deg, rgba(0, 0, 0, 0) 29.41%, #000 60.78%); */
    /* background: linear-gradient(290deg, rgba(0, 0, 0, 0) 29.41%, #000 75.78%); */
    z-index: 1;
    @media (max-width: 767px) {
      background: linear-gradient(167deg, rgba(0, 0, 0, 0) 14.41%, #000 32.78%);
    }
  }
}

.content {
  display: flex;
  /* margin-bottom: 107px; */
  width: 100%;
  align-items: center;
  max-width: var(--contentMaxWidthPages);
  margin: 70px auto 0;
  padding: 40px 108px 40px 167px;
  position: relative;
  z-index: 2;

  @media (max-width: 1260px) {
    padding: 40px 40px 40px 108px;
  }

  @media (max-width: 1023px) {
    padding: 40px 40px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 95px 40px 40px;
  }
}

/* .container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://images.pexels.com/photos/28292140/pexels-photo-28292140/free-photo-of-a-sunrise-over-a-valley-with-trees-and-hills.jpeg'); 
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
} */

.leftSection {
  cursor: pointer;
  flex: 1;
  max-height: 400px;
  max-width: 269px;
  border-radius: 8px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  @media (max-width: 1023px) {
    max-height: 200px;
    max-width: 150px;
  }

  & img {
    max-width: 269px;
    max-height: 400px;

    @media (max-width: 1023px) {
      max-height: 200px;
      max-width: 150px;
      width: 148px;
      height: 220px;
    }
  }
}

.poster {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.rightSection {
  flex: 2;
  padding-left: 61px;
  max-width: 850px;
  word-break: break-word;
  @media (max-width: 1023px) {
    padding-left: 31px;
  }

  @media (max-width: 767px) {
    padding-left: 0;
  }
}

.title {
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px;
  /* 110% */
  margin: 0 0 15px;
  padding: 0;

  @media (max-width: 1023px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 767px) {
    line-height: 60px;
    text-align: center;
    margin: 0 0 6px;
  }
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ratingValue {
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
}

.stars {
  font-size: 20px;
  color: gold;
}

.movieDetails {
  margin-bottom: 23px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.primaryGenere {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  /* 244.444% */
  margin: 0 0 14px;
  padding: 0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    /* flex-direction: column; */
    justify-content: center;
    margin: 0 0 34px;
  }

  & p {
    margin: 0;
    padding: 0;
  }
}

.rating {
  color: var(--White, #fff);
  font-family: 'Sofia Pro';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 109.091% */
  letter-spacing: -0.348px;
  margin: 0 0 12px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.actions {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    gap: 20px;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.buyButton,
.watchTrailerButton,
.wishlistButton {
  border: none;
  padding: 12px 24px 10px;
  min-height: 48px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.5px;
  white-space: nowrap;
}

.buyButton {
  composes: buttonSecondary from global;
  min-height: 48px;
  max-width: 190px;
  white-space: nowrap;

  @media (max-width: 1023px) {
    width: max-content;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 318px;
  }
}

.buyButton:hover {
  background-color: #d4d4d6;
  min-height: 48px;
}

.watchTrailerButton {
  border: 2px solid #fff;
  composes: buttonDefaultResting from global;
  height: 48px;
  min-height: 48px;

  @media (max-width: 1023px) {
    min-height: 40px;
    max-width: 176px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 318px;
  }

  & span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    & g {
      & path {
        fill: red;
      }
    }
  }
}

.wishlistButton {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
  border: 0;
  padding: 0;
  min-height: 48px;
  background: none;

  @media (max-width: 1023px) {
    padding: 0;
    width: max-content;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 318px;
  }
  &:hover {
    background: none;

    &::before {
      content: unset;
    }
  }
}

.description {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  max-width: 700px;
  /* 152.941% */
  letter-spacing: -0.348px;

  & p {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  & span {
    margin: 0;
    padding: 0;
    display: inline-block;
    word-break: break-word;
  }
  & * {
    word-break: break-word;
  }
  & .readLess {
    display: block;
  }
  & ol {
    padding-left: 16px;
    margin-bottom: 4px;
  }
}

.readMore {
  color: #f5b342;
  cursor: pointer;
}

.subtitleContainer {
  position: relative;
  width: max-content;

  & .subtitleHeading {
    cursor: pointer;
    display: inline;
  }

  & .subtitles {
    display: none;
    position: absolute;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12),
      0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 4;

    & li {
      color: var(--colorBlack);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: 0.25px;
      text-align: left;
      padding-left: 10px;
    }
  }

  & .subtitles.show {
    display: block;
    padding: 2px 2px;
    min-width: 138px;
    top: 14px;
    right: 0;

    @media (max-width: 1023px) {
      left: 0;
    }
  }
}

.trailerModal {
  & > div {
    @media (max-width: 1023px) {
      align-items: center;
      height: auto !important;
      max-height: calc(100vh - 100px) !important;
      min-height: calc(100vh - 100px) !important;
    }

    & > div {
      padding: 0 !important;

      @media (max-width: 1023px) {
        align-items: center;
        height: auto !important;
        max-height: calc(100vh - 100px) !important;
        min-height: auto !important;
      }
    }
  }
}

.missingTrailer {
  padding: 1rem 1.5rem;
  height: 200px;
  text-align: center;
}

.reviews {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 21px;
  }
  & svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.sliderContainer {
  max-width: 100vw;
  overflow: hidden;
  margin-top: -71px;

  @media (max-width: 767px) {
    margin-top: -89px;
  }
  & :global(.slick-slide) {
    transition: transform 0.5s ease;
    opacity: 0;
  }
  
  & :global(.slick-active) {
    opacity: 1;
  }

  & :global(.slick-dots) {
    left: 100px;
    bottom: 0;
    top: 0;
    right: unset;
    width: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0px 0px 0px calc((100% - 1686px) / 2);

    @media (max-width: 1260px) {
      left: 40px;
    }

    @media (max-width: 1023px) {
      left: 0;
      bottom: 20px;
      right: 0;
      top: unset;
      flex-direction: row;
    }
  }
}
