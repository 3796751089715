.video {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  @media (max-width: 767px) {
    & img {
      object-fit: cover;
      width: 100%;
      height: 361px;
    }
  }
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    & svg {
      width: 62.343px;
      height: 62.475px;
    }
  }
}
