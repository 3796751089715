.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 41px;
  @media (--viewportLarge) {
    padding: 0 60px;

  }
  @media (min-width: 1580px) {
    padding: 0 104px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
