@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  & h3 {
    max-width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 12px;
    padding: 0;
  }

  &>p {
    @media (min-width: 768px) {
      max-width: 520px;
    }
  }

  &>h4 {
    margin-bottom: 35px;
  }

  &>a {
    width: max-content;
    min-height: 48px;
    margin: 0;
    padding: 16px 0 16px 0;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    font-weight: var(--fontWeightSemiBold);
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 50px;
    background-color: var(--colorPrimaryButton);
    color: var(--colorWhite);

    padding: 12px 20px 11px 20px;
    margin-top: 65px;

    &:hover {
      outline: none;
      box-shadow: var(--boxShadowButton);
      background-color: var(--colorPrimaryButtonDark);
      color: var(--colorWhite);
    }
  }
}

:global(#landing-hero) {
  min-height: 601px;
  margin-top: -72px;
  padding-top: 72px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #040201 45.45%, rgba(0, 0, 1, 0) 58.99%);

    @media (max-width: 767px) {
      content: unset;
    }
  }

  &>div {
    padding: 0;

    &:first-child {
      position: relative;

      @media (max-width: 767px) {
        min-height: 250px;
      }

      @media (--viewportMedium) {
        left: 40%;
        position: absolute;
      }
    }

    &:last-child {
      position: relative;
      z-index: 2;

      &>div {
        @media (min-width: 768px) {
          grid-template-columns: minmax(0, 740px) 400px;
        }

        @media (max-width: 1023px) {
          display: block;
          padding: 37px 25px 37px 40px;
        }
      }
    }
  }

  & .text {
    & h3 {
      font-size: 18px;

      font-style: normal;
      font-weight: 600;
      line-height: normal;

      letter-spacing: -0.348px;
      margin: 0 0 28px;
      padding: 0;

      @media (--viewportLarge) {
        line-height: 30px;
        /* 125% */
        font-size: 24px;
        margin: 0 0 16px;
      }
    }

    & h1 {
      font-size: 40px;
      line-height: 48px;
      font-style: normal;
      font-weight: 400;
      margin: 0 0 16px;
      padding: 0;
      max-width: 740px;

      @media (--viewportLarge) {
        line-height: normal;
        font-size: 60px;
      }
    }

    & h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 137.5% */
      margin: 0;
      max-width: 740px;
    }

    & ul {
      display: flex;
      padding: 0;
      gap: 15px;
      margin-top: 47px;

      @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 44px;
      }

      & li {
        list-style: none;

        &:last-child {
          & a {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            min-height: auto;
            margin: 0;
            height: auto;
            font-size: 16px;
            padding: 12px 0 12px 0;
            transition: all var(--transitionStyleButton);
            cursor: pointer;
            font-weight: var(--fontWeightBold);
            text-align: center;
            text-decoration: none;
            border-width: 0px;
            border-style: solid;
            border-color: var(--colorSecondaryButton);
            border-radius: 50px;
            background-color: var(--colorSecondaryButton);
            color: var(--Background);

            @media (max-width: 767px) {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &:hover {
              background-color: #ff0000;
              color: #fff;
            }

            &::before {
              content: '';
              width: 17px;
              height: 17px;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path d="M5.375 12L1.44416 15.9308" stroke="%23171819" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/><circle cx="5.625" cy="5.625" r="5.625" transform="matrix(-1 0 0 1 15.125 1.5)" stroke="%23171819" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/></svg>');
              background-repeat: no-repeat;
            }

            &:hover::before {
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path d="M5.375 12L1.44416 15.9308" stroke="%23FFFFFF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/><circle cx="5.625" cy="5.625" r="5.625" transform="matrix(-1 0 0 1 15.125 1.5)" stroke="%23FFFFFF" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/></svg>');
            }

            padding: 12px 20px 12px 20px;

            @media (--viewportMedium) {}
          }
        }

        &:first-child {
          & a {
            display: block;
            width: 100%;
            min-height: 48px;
            margin: 0;
            padding: 16px 0 16px 0;
            transition: all var(--transitionStyleButton);
            cursor: pointer;
            font-weight: var(--fontWeightSemiBold);
            text-align: center;
            text-decoration: none;
            border: none;
            border-radius: 50px;
            background-color: var(--colorPrimaryButton);
            color: var(--colorWhite);

            &::before {
              content: '';
              width: 15px;
              height: 15px;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><g clip-path="url(%23clip0_4260_2292)"><path d="M8.8623 5.27246H1.3623C0.612305 5.27246 0 5.88477 0 6.6377V14.1377C0 14.8877 0.612305 15.5 1.3623 15.5H8.8623C9.61523 15.5 10.2246 14.8877 10.2246 14.1377V6.6377C10.2275 5.88477 9.61523 5.27246 8.8623 5.27246ZM10.9102 8.68262V12.0928L15 14.1377V6.6377L10.9102 8.68262ZM2.04492 4.58984C3.17578 4.58984 4.08984 3.67285 4.08984 2.54492C4.08984 1.41699 3.17578 0.5 2.04492 0.5C0.916992 0.5 0 1.41699 0 2.54492C0 3.67285 0.916992 4.58984 2.04492 4.58984ZM8.18262 0.5C7.05176 0.5 6.1377 1.41699 6.1377 2.54492C6.1377 3.67285 7.05469 4.58984 8.18262 4.58984C9.31348 4.58984 10.2275 3.67285 10.2275 2.54492C10.2275 1.41699 9.31055 0.5 8.18262 0.5Z" fill="white"/></g><defs><clipPath id="clip0_4260_2292"><rect width="15" height="15" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>');
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }

            padding: 12px 20px 11px 20px;

            @media (--viewportMedium) {}

            &:hover {
              outline: none;
              box-shadow: var(--boxShadowButton);
              background: linear-gradient(92deg, #f00 1.66%, #900 100.41%);
              color: var(--colorWhite);
            }
          }
        }
      }
    }
  }
}

:global(#ready-set-rabel) {
  &>div {
    &>div {
      display: flex;
      gap: 22px;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media (--viewportLarge) {
        gap: 72px;
      }

      &>div:first-child {
        width: 100%;
        max-width: 828px;
      }

      &>div:last-child {
        width: 100%;
        max-width: 462px;
      }
    }
  }

  & .text {
    @media (max-width: 767px) {
      margin-top: 65px;
    }

    & h3 {
      max-width: 100%;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 122.222% */
      margin: 0 0 12px;
      padding: 0;
    }

    & h4 {
      max-width: 100%;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 35px;
      padding: 0;

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.348px;
        margin: 0 0 19px;
      }
    }

    & p {
      max-width: 100%;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.348px;
      margin: 0 0 41px;
      padding: 0;

      @media (max-width: 767px) {
        margin: 0 0 37px;
      }
    }

    & a {
      display: block;
      width: 100%;
      min-height: 48px;
      max-width: 215px;
      margin: 0;
      padding: 16px 0 16px 0;
      transition: all var(--transitionStyleButton);
      cursor: pointer;
      font-weight: var(--fontWeightSemiBold);
      text-align: center;
      text-decoration: none;
      border: none;
      border-radius: 50px;
      background-color: var(--colorPrimaryButton);
      color: var(--colorWhite);

      @media (--viewportMedium) {
        padding: 12px 20px 11px 20px;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }

      &:hover {
        outline: none;
        box-shadow: var(--boxShadowButton);
        background: linear-gradient(92deg, #f00 1.66%, #900 100.41%);
        color: var(--colorWhite);
      }
    }
  }

  & .media {
    &>div {
      &>div {
        padding-bottom: 61.25% !important;
      }
    }
  }
}

:global(#you-are-in-control) {
  &>div {
    &>div {
      align-items: center;

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 60px;

        & .root {
          width: 100%;
        }
      }

      & .text {
        display: flex;
        flex-direction: column;

        & h4 {
          margin: 0;

          @media (max-width: 767px) {
            font-size: 36px;
            line-height: normal;
          }
        }

        & ul {
          margin-top: 50px;
          padding: 0;
          display: flex;
          gap: 42px;
          flex-wrap: wrap;
          max-width: 530px;

          @media (max-width: 767px) {
            margin-top: 29px;
          }

          & li {
            list-style: none;
            width: 50%;
            max-width: 230px;

            @media (max-width: 767px) {
              width: 100%;
              max-width: 100%;
            }

            & strong {
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              /* 120% */
              letter-spacing: -0.348px;
              display: block;
              margin-bottom: 14px;
            }

            & em {
              display: block;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              /* 125% */
            }
          }
        }

        & a {
          display: flex;
          width: 164px;
          height: 48px;
          padding: 12px 20px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 50px;
          background: #f00;
          margin-top: 50px;

          &:hover {
            outline: none;
            box-shadow: var(--boxShadowButton);
            background: linear-gradient(92deg, #f00 1.66%, #900 100.41%);
            color: var(--colorWhite);
          }

          @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

:global(#earn-what-your-should),
:global(#grid-column-3),
:global(#grid-column-2) {
  &>div:last-child {
    padding-bottom: 157px;

    @media (max-width: 767px) {
      padding-bottom: 161px;
      padding-top: 0;
    }

    &>div {
      @media (--viewportLarge) {
        padding: 64px 70px 0 70px;
      }

      @media (min-width: 1580px) {
        padding: 64px 170px 0 170px;
      }
    }
  }

  & header {
    @media (max-width: 767px) {
      text-align: center;
      display: block;
      margin-top: 65px;
    }

    & h2 {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 122.222% */
      margin: 0 0 7px;
      padding: 0;

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
    }

    & p {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 0px;
      padding: 0;

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  & .root {
    border-radius: 8px;
    border: 1px solid #fff;
    background: #121213;
    padding: 49px 43px 58px;

    @media (max-width: 1023px) {
      padding: 49px 23px 58px;
    }

    & .media {
      width: 36px;
      height: 36px;
      margin: 0 auto 36px;
    }

    &:first-child {
      & .media {
        width: 36px;
        height: 36px;
        margin: 0 auto 36px;
      }
    }

    &:nth-child(2) {
      & .media {
        width: 47px;
        height: 47px;
        margin: 0 auto 33px;
      }
    }

    &:nth-child(3) {
      & .media {
        width: 55px;
        height: 55px;
        margin: 0 auto 23px;
      }
    }

    & .text {
      & h3 {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        /* 125% */
        letter-spacing: -0.348px;
        margin: 0 0 18px;
        padding: 0;
      }

      & p {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.348px;
        padding: 0;
        margin: 0 0 36px;
      }

      & a {
        display: flex;
        width: max-content;
        height: 48px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 50px;
        background: #f00;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.3px;
        margin: 0 auto;

        &:hover {
          outline: none;
          box-shadow: var(--boxShadowButton);
          background: linear-gradient(92deg, #f00 1.66%, #900 100.41%);

          color: var(--colorWhite);
        }
      }
    }
  }
}

:global(#grid-column-3),
:global(#grid-column-2) {
  @media (--viewportMedium) {
    &>div:last-child {
      padding-bottom: 50px;
    }
  }
}

:global(#reviews) {
  & header {
    @media (max-width: 767px) {
      text-align: center;
      display: block;
    }

    & h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 122.222% */
      margin: 0 0 21px;
      padding: 0;
      max-width: 100%;

      @media (max-width: 767px) {
        text-align: center;
        display: block;
      }
    }

    & p {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  & :global(#block-4),
  & :global(#reviews-block-1) {
    & .text {
      &:before {
        content: '';
        width: 42px;
        height: 37px;
        display: block;
        margin-bottom: 10px;
        background-image: url('../../../../assets/rabel_logo.png');
      }
    }
  }

  &>div {
    padding-top: 99px;

    &>div {
      display: block;

      & .text {
        padding: 30px 23px;
        margin-top: 0;

        @media (--viewportLarge) {
          padding: 20px 14px;
        }

        @media (min-width: 1400px) {
          padding: 30px 23px;
        }

        & h3 {
          color: #fff;
          font-family: 'Sofia Pro';
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0;
          margin: 0 0 9px;
        }

        & h6 {
          margin: 0 0 14px;
          padding: 0;
          color: #f5d90a;
          line-height: 12px;
        }

        & p {
          color: #fff;

          /* Body 1 Regular */
          font-family: 'Sofia Pro';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 125% */
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

/* Template components */

:global(#video-section) {
  & header {
    display: block;
    text-align: center;

    & h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }

    & p {
      font-size: 36px;
      font-weight: 500;
      line-height: 36px;
      margin-top: 20px;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }
  }

  & .text {
    & p {
      max-width: 874px;
      margin: 0 auto;
      text-align: center;
    }

    & a {
      margin-top: 33px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  & img {
    margin: 0 auto;
    width: 100%;
  }
}

:global(#go-over-image) {
  min-height: 677px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    min-height: 677px;
    min-height: auto;
    padding: 30px 0;
  }

  &>div:last-child {
    width: 100%;
  }

  & .text {
    max-width: 1131px;
    width: 100%;
    margin: 0 auto;

    & p {
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px;
      /* 114.583% */
      width: 100%;
      max-width: 100%;

      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 44px;
      }
    }

    & a {
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 16px;
      letter-spacing: 0.3px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

:global(#content-display) {
  & header {
    display: block;
    text-align: center;

    & h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      padding: 0;
      margin: 0 0 21px;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }

    & p {
      color: #fff;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }
  }

  & .text {
    & p {
      max-width: 874px;
      margin: 0 auto;
      text-align: center;
    }

    & a {
      display: block;
      margin-top: 33px;
      width: max-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

:global(#faq-section) {
  & header {
    display: block;
    text-align: center;

    & h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      padding: 0;
      margin: 0 0 21px;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }

    & p {
      color: #fff;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      max-width: 100%;
      text-align: center;
      width: 100%;
    }
  }
}

:global(#creators) {
  & .root {
    display: flex;
    gap: 37px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  & .media {
    width: 182px;
    height: 182px;
    min-width: 182px;

    & img {
      width: 182px;
      height: 182px;
    }
  }

  & .text {
    margin: 0;

    @media (min-width: 1023px) {
      max-width: 434px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    & h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 488.889% */
      margin: 0;
      padding: 0;
    }

    & h4 {
      font-size: 33px;
      font-style: normal;
      font-weight: 900;
      line-height: 44px;
      /* 266.667% */
      margin: 0;
      padding: 0;
    }

    & p {
      line-height: 24px;
    }
  }
}

:global(#content-carousel),
:global(#creator-carousel) {
  & .media {
    border-radius: 8px;
    box-shadow: inset 0 4px 4px 0 #00000040;
    height: 399px;
    position: relative;

    & img {
      border-radius: 8px;
      box-shadow: inset 0 4px 4px 0 #00000040;
      height: 399px;
      position: relative;
    }
  }

  & .text {
    & h6 {
      color: #f5d90a;
      line-height: 12px;
      font-size: 14px;
      margin: 0 0 14px;
      padding: 0;
    }

    & h5 {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    & h4 {
      font-size: 20px;
      line-height: 20px;
      margin: 12px 0 7px;
      font-style: normal;
      font-weight: 500;
      padding: 0;
      text-align: left;
    }

    & p {
      color: #fff;
      font-size: 13px;
      line-height: 22px;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      padding: 0;
      text-align: left;
    }

    & a {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;

      z-index: 2;
      opacity: 0;
      max-width: 100%;
      width: 100%;
    }
  }
}

.ctaButtons {
  display: flex;
  gap: 15px;
  margin-top: 47px !important;

  white-space: nowrap;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  & a {
    padding: 12px 20px;
    width: max-content;
    min-height: 48px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }

    & svg {
      margin-right: 10px;
      fill: transparent;
    }
  }
}

.ctaButton {
  composes: buttonPrimary from global;
  & span{
    color: #fff !important;
  }
}

.ctaButtonSecondary {
  composes: buttonSecondary from global;
  & span{
    color: #000 !important;
  }
}
