@import '../assets/sanitize.css';
@import './customMediaQueries.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  /* Colors used for different functions (like error texts) */

  --colorSuccess: #2ecc71;
  --colorSuccessDark: #239954;
  --colorSuccessLight: #f0fff6;
  --colorFail: #ff0000;
  --colorFailLight: #fff0f0;
  --colorAttention: #ffaa00;
  --colorAttentionLight: #fff7f0;
  --colorBannedLight: var(--marketplaceColorLight);
  --colorBannedDark: var(--marketplaceColor);

  /* B&W and Grey palette */

  --accent: #f5d90a;
  --gradientBase: #8e1416;
  --Contrast: #ef4444;
  --Background: #171819;
  --backgroundSecondaryOutline: #353535;
  --colorWhite: hsl(0 0% 100%);
  --colorGrey50: hsl(240 3% 98%);
  --colorGrey100: hsl(240 3% 90%);
  --colorGrey200: hsl(240 3% 80%);
  --colorGrey300: hsl(240 3% 70%);
  --colorGrey400: #9d9d9d;
  --colorGrey500: #aeaeae;
  --colorGrey600: hsl(240 3% 40%);
  --colorGrey700: #b2b2b2;
  --colorGrey800: hsl(240 3% 20%);
  --colorGrey900: hsl(240 3% 10%);
  --colorBlack: hsl(0 0% 0%);
  --Dark-Grey: #343434;
  --Mid-Grey: #9D9D9D;

  --colorLightTransparent: rgba(255, 255, 255, 0.65);

  /**
  * Marketplace color comes by default from src/config/brandingConfig.js
  * It overwrites these CSS Properties.
  * In the future, we are planning to bring more --marketplace* CSS Properties
  * from hosted assets, which means that they could be managed through Console.
  */
  --marketplaceColor: #ed2124; /* hsl(260, 63%, 46%) */
  --marketplaceColorLight: #7748d5; /* hsl(260, 63%, 56%), +10L from marketplaceColor */
  --marketplaceColorDark: #492296; /* hsl(260, 63%, 26%), -10L from marketplaceColor */

  /** 
   * The fill color used by <PrimaryButton>
   * These might be overwritten by values from hosted assets
   */
  --colorPrimaryButton: var(--colorSuccess);
  --colorPrimaryButtonDark: var(--colorSuccessDark);
  --colorPrimaryButtonLight: var(--colorSuccessLight);

  /** 
   * The fill color used by <SecondaryButton>
   */
  --colorSecondaryButton: var(--colorWhite);
  --colorSecondaryButtonDark: var(--colorWhite);
  --colorSecondaryButtonLight: var(--colorWhite);

  /* ================ Font ================ */
  --fontFamily: 'Sofia Pro', 'Inter', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightHeavy: 800;
  --fontWeightBlack: 900;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing units ================ */

  /**
   * Note: changing --contentMaxWidth does not affect layouts with LayoutSideNavigation or search pages.
   */
  --contentMaxWidth: 1207px;
  --contentMaxWidthPages: 1680px; /* calc(Global width (1056px) + (Horizontal padding (32px) * 2) */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMedium: 4px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 89px;
  --topbarHeightDesktop: 72px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 35px 45px 46px 51px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */
  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ Inputs, textareas and selects ================ */
  --borderErrorField: 1px solid var(--colorFail);

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 157px;

  /* ================ PageBuilder ================ */
  /* --carouselWidth will be updated dynamically through JS */
  --carouselWidth: 100vw;
}

/* ================ Global element styles ================ */
/**
 * Here are the global styles: for elements as well as some
 * global classes that are composed into component styles.
 *
 * NOTE: components use CSS Modules for styles.
 */

body {
  font-family: var(--fontFamily);

  /* A non-standard feature: https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth */
  text-rendering: optimizeLegibility;
  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-optical-sizing: auto;

  background-color: var(--Background);
  color: var(--colorWhite);
}

::selection {
  background: #343434; /* WebKit/Blink Browsers */
  color: var(--colorWhite);
}

::-moz-selection {
  background: #343434; /* Gecko Browsers */
  color: var(--colorWhite);
}

/* Default elements that are targets */
/* In addition, let's ensure that section components have scroll-margin */
/* Since target does not work in every situation */
:target,
section {
  scroll-margin-top: var(--topbarHeight);

  @media (--viewportMedium) {
    scroll-margin-top: var(--topbarHeightDesktop);
  }
}

a {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  padding: 3px 0 3px 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 80px;
    line-height: normal;
    padding: 0;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

h2 {
  font-size: 21px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 60px;
    line-height: normal;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

h3 {
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 24px;
  /* x * 6px */
  margin-top: 18px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: normal;
    padding: 1px 0 7px 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

h4 {
  font-weight: var(--fontWeightMedium);
  font-size: 21px;
  line-height: 30px;
  padding: 1px 0 5px 0;
  /* x * 6px */
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: normal;
    padding: 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

h5 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;
  /* x * 6px */
  padding: 3px 0 3px 0;
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 24px;

    line-height: 20px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

h6 {
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 18px;
  /* x * 6px */
  padding: 4px 0 2px 0;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.348px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

html,
li,
p,
pre {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);

  /* No margins for default font */
  font-size: 14px;
  line-height: 24px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0 3px 0;
  }
}

p,
pre {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

/* ================ Normalisations ================ */

html {
  /* font is set earlier */
  color: var(--colorGrey700);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend {
  display: block;

  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

label {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 6px;
    font-size: 15px;
  }
}

button {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
}

/* marketplaceInputFontStyles */
select,
input,
textarea {
  font-family: var(--fontFamily);
  font-size: 16px;
}

select {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  padding: 6px 24px 4px 12px;
  box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7);

  /* Unset user agent styles */
  appearance: none;

  /* Background */
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%232D4053' stroke='%232D4053' strokeWidth='.5' fill-rule='evenodd'%3E%3Cpath d='M4.687 15.315a.443.443 0 0 0 .626 0l3.557-3.556a.446.446 0 0 0-.629-.629L5 14.37l-3.241-3.24a.445.445 0 0 0-.629.63l3.557 3.553v.002ZM5.313 1.13a.443.443 0 0 0-.626 0L1.13 4.685a.446.446 0 0 0 .629.63L5 2.074l3.241 3.24a.445.445 0 0 0 .629-.629L5.313 1.131V1.13Z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center right 12px;
  background-size: 8px 14px;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    padding: 4px 32px 3px 16px;
    line-height: 32px;
    background-position: center right 16px;
    background-size: 10px 16px;
  }
}
/* Effects */
select:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
select:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
select:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

input,
textarea {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 2px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;
  color: var(--colorBlack);
  font-size: 14px;
  font-weight: 500;
  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px 16px 7px 16px;
    line-height: 26px;
  }
}
/* Effects */
input:hover,
textarea:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
input:focus,
textarea:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
input:disabled,
textarea:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
}

textarea {
  min-height: 84px; /* 3 rows  */
  max-height: 300px; /* 12 rows fullu visible, scroll after */

  @media (--viewportMedium) {
    min-height: 112px; /* 3 rows fully visible */
    max-height: 496px; /* 15 rows fullu visible, scroll after */
    line-height: 24px;
  }
}

/* ================ Plain global CSS glasses ================ */

/**
 * Note: even though these reflect element styles, 
 * these are below element styles to allow overwrites using classes.
 */

.a {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}
.a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.h1 {
  font-weight: var(--fontWeightRegular);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 80px;
    line-height: normal;
    padding: 0;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.h2 {
  font-weight: var(--fontWeightRegular);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 60px;
    line-height: normal;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.h3 {
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 24px;
  /* x * 6px */
  margin-top: 18px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: normal;
    padding: 1px 0 7px 0;
    letter-spacing: -1px;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.h4 {
  font-weight: var(--fontWeightMedium);
  font-size: 21px;
  line-height: 30px;
  padding: 1px 0 5px 0;
  /* x * 6px */
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: normal;
    padding: 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.h5 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;
  /* x * 6px */
  padding: 3px 0 3px 0;
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 24px;

    line-height: 20px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.h6 {
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  /* x * 6px */
  padding: 4px 0 2px 0;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.348px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.label {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 13px;
  }
}

.p,
.buttonDefault,
.buttonPrimary,
.buttonPrimaryInline,
.buttonSecondary,
.buttonSecondaryInline,
.marketplaceMessageFontStyles,
.marketplaceTxTransitionFontStyles,
.marketplaceListingAttributeFontStyles,
.marketplaceBodyFontStyles,
.marketplaceDefaultFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);

  /* No margins for default font */
  font-size: 14px;
  line-height: 24px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0 3px 0;
  }
}

.p,
.marketplaceBodyFontStyles {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.textLarge {
  font-size: 20px;
  line-height: 30px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    line-height: 32px;
  }
}

.textSmall {
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.1px;

  @media (--viewportMedium) {
    line-height: 22px;
  }
}

.textXSmall {
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

/* ================ Other fonts ================ */

.marketplaceSmallFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.marketplaceTinyFontStyles {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
}

/* .marketplaceMessageFontStyles is defined earlier */
/* .marketplaceTxTransitionFontStyles is defined earlier*/
/* .marketplaceListingAttributeFontStyles is defined earlier */

.marketplaceMessageDateFontStyles {
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 12px;
    line-height: 24px;
  }
}

.marketplaceSearchFilterLabelFontStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.marketplaceSearchFilterSublabelFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 18px;
}

/* ================ Global class for input styles ================ */

.marketplaceInputStyles {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px 16px 7px 16px;
    line-height: 32px;
  }
}
/* Effects */
.marketplaceInputStyles:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
.marketplaceInputStyles:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
.marketplaceInputStyles:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

/* ================ Tabbed navigation font styles ================ */

.marketplaceTabNavFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;
  }
}

.marketplaceTabNavHorizontalFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

/* ================ Clearfix solution ================ */

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/* ================ Buttons ================ */

/**
 * For actual buttons, use components: <Button>, <PrimaryButton>, <SecondaryButton>
 *
 * To create button look-and-feel for links, etc. combine button classes in CSS Modules:
 * 
 * <Button>:
 *   composes: buttonDefault from global;
 * <PrimaryButton>:
 *   composes: buttonPrimary from global;
 * <SecondaryButton>:
 *   composes: buttonSecondary from global;
 * <SecondaryButtonInline>:
 *   composes: buttonSecondaryInline from global;
 */

/**
 * Styles for <Button>
 */
.buttonDefault {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: 56px;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 16px 20px 16px 20px;

  transition: background 0.5s ease-in-out;
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  /* Default button has no borders */
  border: none;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  /* Button colors */
  /* Default button uses marketplace color */
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  & span {
    position: relative;
    z-index: 2; /* Keep text above the gradient */
  }
  &:focus {
    border: 2px solid #fff;
    background: var(--marketplaceColor) !important;
    &:before {
      content: none;
    }
  }
}

.buttonDefault::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%; /* Start off-screen (right side) */
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #ed2124 0%, #871315 100%);
  transition: left 0.5s ease-in-out; /* Smooth transition of gradient */
  z-index: 1;
}

.buttonDefault:hover::before {
  left: 0; /* Move gradient from right to left on hover */
}
.buttonDefault:focus,
.buttonDefault:hover {
  outline: none;
  box-shadow: 0px 4px 4px 0px #00000040;

  background: linear-gradient(90deg, #ed2124 0%, #871315 100%);
}
.buttonDefault:disabled {
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  background-color: var(--colorGrey500);
  color: var(--colorWhite);
}

.buttonDefault:disabled::before {
  content: unset !important;
  background-color: var(--colorGrey500);
}
.buttonDefault:disabled:hover {
  background: var(--colorGrey500);
  color: var(--colorWhite);
}

.buttonDefaultResting {
  display: flex;
  height: 60px;
  padding: 12px 74px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #fff;
  background: #000;
  width: max-content;
  border-radius: 100px;
  &:before {
    content: unset;
  }
  @media (max-width: 767px) {
    height: 48px;
    padding: 12px 24px;
  }
}

.buttonDefaultResting:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  background: #000;
}

/**
 * Styles for <PrimaryButton>
 */
.buttonPrimary {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 16px 0 16px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);

  /* Button text styles */
  /* Primary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  /* Primary button has no borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  /* Button colors */
  /* Primary button uses success color */
  background-color: var(--colorPrimaryButton);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 20px 0 20px 0;
  }
}
.buttonPrimary:focus,
.buttonPrimary:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--colorPrimaryButtonDark);
  color: var(--colorWhite);
}
.buttonPrimary:hover {
  text-decoration: none;
}
.buttonPrimary:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
}

/**
 * Styles for <PrimaryButtonInline>
 */
.buttonPrimaryInline {
  display: inline-block;
  padding: 8px 16px;
  margin: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);

  /* Button text styles */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  background-color: var(--colorPrimaryButton);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    line-height: 16px;
  }
}
.buttonPrimaryInline:focus,
.buttonPrimaryInline:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  text-decoration: none;
  background-color: var(--colorPrimaryButtonDark);
  color: var(--colorWhite);
}
.buttonPrimaryInline:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
}

/**
 * Styles for <SecondaryButton>
 */
.buttonSecondary {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: auto;
  margin: 0;
  height: auto;
  font-size: 16px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 12px 0 12px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightBold);

  /* Button text styles */
  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Secondary button has clear borders */
  border-width: 2px;
  border-style: solid;
  border-color: var(--colorSecondaryButton);
  border-radius: 50px;

  /* Button colors: secondary */
  background-color: var(--colorSecondaryButton);
  color: var(--Background);
  @media (--viewportMedium) {
    padding: 12px 0 12px 0;
  }
}
.buttonSecondary:focus,
.buttonSecondary:hover {
  border-radius: 50px;
  outline: none;
  box-shadow: var(--boxShadowButton);
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}
.buttonSecondary:hover {
  text-decoration: none;
  color: var(--colorWhite);
}
.buttonSecondary:focus {
  border: 2px solid #fff;
}
.buttonSecondary:disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--colorGrey400);
  background-color: var(--colorGrey400);
  color: var(--colorBlack);
  @media (--viewportMedium) {
    padding: 12px 0 12px 0;
  }
}

/* Secondary Outline Btn */
.buttonSecondaryOutline {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: auto;
  margin: 0;
  height: auto;
  font-size: 16px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 12px 0 12px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightBold);

  /* Button text styles */
  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Secondary button has clear borders */
  border-width: 2px;
  border-style: solid;
  border-color: var(--colorWhite);
  border-radius: 50px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  /* Button colors: secondary */
  background-color: var(--backgroundSecondaryOutline);
  color: var(--colorWhite);
  @media (--viewportMedium) {
    padding: 12px 0 12px 0;
  }
}
.buttonSecondaryOutline:focus,
.buttonSecondaryOutline:hover {
  border-radius: 50px;
  outline: none;
  box-shadow: var(--boxShadowButton);
  border-color: var(--colorWhite);
  background-color: var(--colorWhite);
  color: var(--colorBlack);
}
.buttonSecondaryOutline:hover {
  text-decoration: none;
  color: var(--colorBlack);
}
.buttonSecondaryOutline:focus {
  border: 2px solid #fff;
}
.buttonSecondaryOutline:disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--colorGrey100);
  background-color: var(--colorGrey100);
  color: var(--colorWhite);
}

/**
 * Styles for <SecondaryButtonInline>
 */
.buttonSecondaryInline {
  /* button defaults */
  display: inline-block;
  width: auto;
  min-height: auto;
  height: 42px;
  margin: 17px 0 0 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 0 16px;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font
   */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Button text styles */
  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Secondary button has clear borders */
  border-width: 1px;
  border-style: solid;
  border-color: var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  /* Button colors: secondary */
  background-color: var(--colorSecondaryButton);
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    height: 40px;
  }
}
.buttonSecondaryInline:focus,
.buttonSecondaryInline:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  border-color: var(--colorGrey300);
  background-color: var(--colorSecondaryButton);
}
.buttonSecondaryInline:hover {
  text-decoration: none;
}
.buttonSecondaryInline:disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--colorGrey100);
}

/* ================ Modals ================ */

.marketplaceModalRootStyles {
  /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

  /* AuthenticationPage's root uses flexbox */
  display: flex;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
  }
}

.marketplaceModalFormRootStyles {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}

.marketplaceModalBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--colorBlack);
  border-radius: var(--borderRadius);
  border-bottom: none;

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    /* min-height: 568px; */
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }
}

.marketplaceModalInMobileBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--colorBlack);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

/* Modal title */
.marketplaceModalTitleStyles {
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  line-height: 36px;
  margin: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightBold);
    line-height: 40px;
    margin: 0;
  }
}

/* Modal message */
.marketplaceModalParagraphStyles {
  font-size: 20px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }
}

/* Bottom wrapper for the modal */
.marketplaceModalBottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 41px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.marketplaceModalBottomWrapperText {
  margin-top: 0px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.marketplaceModalHelperText {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  color: var(--colorGrey300);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalHelperLink {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  color: var(--colorWhite);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

/* Modal Close icon */

.marketplaceModalCloseStyles {
  /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  @media (--viewportMedium) {
    padding: 27px 30px;
    position: absolute;
  }

  /* Colors */
  background-color: transparent;
  color: var(--colorGrey700);

  cursor: pointer;
}
.marketplaceModalCloseStyles:enabled:hover {
  background-color: transparent;
  color: var(--colorBlack);
  box-shadow: none;
  text-decoration: none;
}
.marketplaceModalCloseStyles:enabled:active {
  background-color: transparent;
  color: var(--colorBlack);
}
.marketplaceModalCloseStyles:disabled {
  background-color: transparent;
}

.marketplaceModalIconStyles {
  height: 48px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.marketplaceModalCloseText {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalCloseIcon {
  display: inline-block;
  margin-left: 8px;

  /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;

  @media (--viewportMedium) {
    margin-top: 2px;
  }
}

.marketplaceModalErrorStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;

  margin-top: 24px;
  color: var(--colorFail);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.marketplaceModalPasswordMargins {
  /* Leave space between the input and the button below when the
  viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

:global(.slick-dots){
  left: 0px;
  right: 0;
  bottom: -55px;
  width: auto;
  display: flex !important; 
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 0px 0px calc((100% - 1686px) / 2);
  & li{
    margin: 0 !important;
    padding: 0 !important;
    & button{   
      width: 14px !important;
      height: 14px !important;
      border-radius: 50%;
      background-color: #fff !important;
      border: 2px solid #fff !important;
      padding: 0 !important;
      &::before{
        display: none;
      }
    }
    &:global(.slick-active){
      & button{
        background-color: #000 !important;
        border: 2px solid #fff !important;
      }
    }
  }
}