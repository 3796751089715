@import '../../../../styles/customMediaQueries.css';

.creatorSpotlight {
  background: var(--Slate, #171819);
}

.creatorSpotlightContainer {
  color: #fff;
  padding: 4rem 2rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 46px;
  max-width: 100vw;
  overflow: hidden;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding: 32px;
  width: 100%;
  padding: 53px 0px 31px 37px;
  @media (--viewportLarge) {
    padding: 85px 0px 55px 60px;
    gap: 40px;
  }
  @media (min-width: 1580px) {
    padding: 145px 0 55px 104px;
    gap: 103px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.content {
  max-width: 500px;
  @media (max-width: 1023px) {
    max-width: 100%;
    padding-right: 32px;
  }
}

.tagline {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0 0 12px;
  padding: 0;
}

.title {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 23px;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0 0 27px;
  }
}

.description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  padding: 0;
  margin: 0 0 56px;
  @media (max-width: 767px) {
    margin: 0 0 42px;
  }
}

.button {
  display: flex;
  width: 164px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.creatorCard {
  cursor: pointer;
  padding: 0 18px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 767px) {
    padding: 0 26px 0 0;
  }
}

.creatorCardContent {
  background-color: #000;
  min-height: 436px;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 767px) {
    background: #171819;
    border-radius: 8px;
    border: 1px solid #000;
  }
}

.creatorInfo {
  position: relative;
}

.creatorImage {
  width: 100%;
  height: 278px;
  object-fit: cover;
}

.creatorInfoContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  padding-bottom: 24px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(360deg, #121213 12.73%, rgba(0, 0, 0, 0) 98.71%);
  @media (max-width: 767px) {
    display: none;
  }
}

.usernameDesktop {
  color: #fff;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 12px;
  @media (max-width: 767px) {
    display: none;
  }
}

.usernameMobile {
  color: #fff;
  text-align: left;
  font-family: 'Sofia Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 15px 0 12px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.creatorName {
  color: #fff;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 71.429% */
  letter-spacing: -0.348px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.bookGridContainer {
  padding: 0 32px 32px;
}

.bookGrid {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 18px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #fff;
  padding-top: 32px;
  overflow: hidden;
  @media (max-width: 767px) {
    border-top: 0px solid #fff;
  }
  &.bookGridThree {
    & .bookCover {
      background-color: #171819;
      max-width: 64px;
      max-height: 93px;
    }
  }
}

.plus {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 44px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 54.545% */
  letter-spacing: -0.348px;
}

.bookCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 83px;
  max-height: 123px;
  border-radius: 8px;
}

.sliderContainer {
  position: relative;
}

.sliderContainer {
  max-width: 100vw;

  @media (--viewportMedium) {
    max-width: calc(100vw - 380px);
  }
  @media (min-width: 1580px) {
    max-width: calc(100% - 405px);
  }
  @media (max-width: 1023px) {
    max-width: calc(100vw - 40px);
  }
  @media (max-width: 767px) {
    padding-bottom: 50px;
    & :global(.slick-track) {
      display: flex !important;
    }
    & :global(.slick-dots) {
      display: flex !important;
      z-index: 2;
    }
  }
}

/* Override default slick slider styles */
:global(.slick-prev),
:global(.slick-next) {
  width: 40px;
  height: 40px;
  z-index: 1;
}

:global(.slick-prev) {
  left: -50px;
}

:global(.slick-next) {
  right: -50px;
}

.prevArrow {
  border: 0;
  position: absolute;
  left: -20px;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  display: none;
  & svg {
    transform: rotate(180deg);
  }
}

.nextArrow {
  border: 0;
  position: absolute;
  right: 0;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
}

.smallImgPlaceholder {
  margin-top: 0px;
  text-align: center;
  & p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    margin-bottom: 0;
  }
}
