.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
  background-color: #000;
  overflow: hidden;
  max-width: 100vw;
  margin-top: -72px;
  padding-top: 72px;
  @media (max-width: 767px) {
    margin-top: -89px;
    padding-top: 89px;
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
