@import '../../../styles/customMediaQueries.css';

.reviews {
  display: flex;
  justify-content: center;
  gap: 20px 24px;

  @media (min-width: 1400px) {

    gap: 50px 59px;
  }

  @media (max-width: 1260px) {
    flex-wrap: wrap !important;
  }

  &>div:nth-child(3) {
    &>div {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.card {
  border-radius: 4px 8px 4px 4px;
  border: 1px solid #fff;
  background: #000;
  width: 100%;
  max-width: 236px;

  @media (max-width: 767px) {
    max-width: 283px;
    margin: 0 auto;
    display: block !important;
  }
}

.reviews_Group {
  width: 100%;
  max-width: 479px;
  display: flex;
  flex-direction: column;
  gap: 50px 59px;

  @media (--viewportLarge) {
    gap: 30px 24px;
  }

  @media (min-width: 1400px) {
    gap: 50px 59px;
  }

  @media (max-width: 1280px) {
    flex-direction: row;
  }

  & .card {
    width: 100%;
    max-width: 100%;

    &>div {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 1280px) {
        flex-direction: column;
      }

      &>div:first-child {
        width: 203px;
        height: 231px;
        min-width: 203px;
      }

      & img {
        width: 203px;
        height: 231px;
      }
    }
  }
}

.button {
  display: flex;
  width: 100%;
  max-width: 259px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #f00;
  color: #fff;
  margin: 66px auto;

  @media (max-width: 1280px) {
    margin: 85px auto 66px;
  }

  &:hover {
    outline: none;
    box-shadow: var(--boxShadowButton);
    background: linear-gradient(92deg, #F00 1.66%, #900 100.41%);
    color: var(--colorWhite);
    text-decoration: none;
  }
}

.reviewsDesktop {
  @media (max-width: 767px) {
    display: none;
  }
}

.reviewsMobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.sliderContainer {
  max-width: calc(100vw - 80px);

  & :global(.slick-track) {
    display: flex !important;
    /* gap: 20px; */
  }
}

.EarnSliderContainer {
  max-width: calc(100vw - 80px);

  @media (max-width: 767px) {
    & :global(.slick-dots) {
      bottom: -105px !important;
    }
  }
}

.accordionItem {
  border-radius: 10px;
  border: 0.5px solid var(--Mid-Grey, #9d9d9d);
  background: var(--Slate, #171819);
  margin-bottom: 20px;
  padding: 28px 56px;

  @media (max-width: 767px) {
    padding: 20px;
  }
}

.accordionContent {
  overflow: hidden;

  & a {
    margin-top: 28px;
    border-radius: 50px;
    border: 1px solid #fff;
    background: #000;
  }
}

.active {
  max-height: 100% !important;
  padding-top: 22px;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  & h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 100% */
    padding: 0;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.accordionWrapper {
  max-width: 1382px;
  margin: 0 auto;
}

.Listingcard{
  overflow: hidden;
  position: relative;
  & > div{
    width: auto !important;
    padding: 0 !important;
    transform: none !important;
    margin: 0 !important;
    max-width: 265px;
  }
}