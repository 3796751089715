@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.customFilterSection {


  gap: 18px;

  padding: 32px;
  width: 100%;
  padding: 32px 40px 30px 40px;
  display: flex;

  @media (--viewportLarge) {
    padding: 59px 60px 94px 60px;
    max-width: 100vw;
    gap: 22px;
  }

  @media (min-width: 1580px) {
    padding: 59px 60px 94px 60px;
    gap: 47px;
    margin: 0 auto;
  }

  @media (min-width: 1679px) {
    padding: 59px 104px 94px 104px;
    max-width: var(--contentMaxWidthPages);
  }

  @media (max-width: 1023px) {
    overflow-x: auto;
    max-width: 100vw;
  }

  & .customFilterItem {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #171819;
    flex: 1;
    padding: 10px 0;
    text-align: center;
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 1500px) {
      font-size: 24px;
    }

    @media (max-width: 1200px) {
      font-size: 24px;
      height: 120px;
    }

    @media (max-width: 1023px) {
      min-width: 186px;
      height: 80px;
    }
  }

  .customFilterItemTitle {
    display: flex;
    align-items: flex-start;
    gap: 22px;

    @media (max-width: 1500px) {
      align-items: center;
      gap: 12px;
    }
    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.customFilterContainer {
  max-width: 100vw;
  overflow: hidden;
}

.comingSoon {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  display: block;
}

.disabled{
  cursor:not-allowed!important;
}