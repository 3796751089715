.categoryFilters {
  margin-top: 10px;
  gap: 30px;
  flex-wrap: wrap;
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
    max-width: calc(100vw - 40px);
    margin-top: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & * {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3px;
    white-space: nowrap;
  }

  & > div {
    padding-bottom: 9px;
  }
}

.genreFilters {
  width: 100%;
  margin-top: 34px;
  gap: 20px;
  flex-wrap: wrap;
  display: flex;

  & select {
    background: #000;
    border: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #fff;
    box-shadow: none;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 8px 34px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.68579 5.31455C4.86 5.48787 5.14 5.48787 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z" fill="white" stroke="white"/></svg>') !important;
    background-repeat: no-repeat !important;
    background-position: 93% 18px !important;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    &:focus,
    &:hover {
      border: none;
      border: 1px solid #fff;
      border-radius: 50px;
      outline: none;
      box-shadow: none;
     
      color: #fff;
      background: #000;
    }
  }

  /* & .genreItems {
    padding: 7px 20px;
    margin: 0;
    line-height: inherit;
    border-radius: 100px;
    border-radius: 100px;
   
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
    min-width: 148px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: 0.3px;
    text-transform: uppercase;
  } */
  & > div {
    padding: 8px 20px 5px;
    margin: 0;
    line-height: 24px;
    border-radius: 100px;
    border: 1.5px solid #2d2e33;
    border-radius: 100px;
    background: #2d2e33;
    min-width: 148px;
    text-align: center;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    &:hover {
      background: #fff;
      color: #000;
    }

    &.activeItem {
      border: 1px solid #fff;
      background: #000;

      &:hover {
        background: #fff;
        color: #000;
      }
    }
  }
}

.activeItem {
  cursor: pointer;
  border-bottom: 2px solid #f00;
}

.customFilterItem {
  cursor: pointer;
}

.menuItem {
  cursor: pointer;
}

.select {
  width: max-content;
  background: #000;
  border: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #fff;
  box-shadow: none;
  border-bottom: 2px solid #ff0000;
  border-radius: 0;
  padding: 0 4px 9px 0;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.68579 5.31455C4.86 5.48787 5.14 5.48787 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z" fill="white" stroke="white"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-position: right 7px !important;
  @media (max-width: 767px) {
    padding-right: 20px;
  }
  &:focus,
  &:hover {
    border: none;
    border-bottom: 2px solid #ff0000;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    color: #fff;
    background: #000;
  }
}
